import { useTranslation } from "react-i18next";
import { PiSignOut } from "react-icons/pi";
import { getAndCapitalizeFirstLetter } from "../../lib/utils";
import { useStore } from "../../store/useStore";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/Avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/DropdownMenu";

export const UserSection = () => {
  const { t } = useTranslation();
  const { user, organization } = useStore();

  const handleSignOut = () => {
    // Must be updated when handling authentication
    localStorage.removeItem("ngStorage-current");
    localStorage.removeItem("ngStorage-orgs");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex items-center p-2 rounded-sm focus:outline-none hover:bg-hoverBackground">
        <Avatar>
          <AvatarImage src={user?.avatar || "https://github.com/shadcn.png"} />
          <AvatarFallback>
            {getAndCapitalizeFirstLetter(user?.name)}
          </AvatarFallback>
        </Avatar>
        <div className="flex-col ml-3 hidden sm:flex text-left">
          <span className="font-normal text-base">{user?.name}</span>
          <span className="text-sm text-secondary">
            {user?.org_name || organization?.name}
          </span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-background">
        <DropdownMenuItem
          className="flex justify-evenly gap-1"
          onClick={handleSignOut}
        >
          <PiSignOut className="size-5" />
          {t("signOut")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
