import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchDashboards } from "../../api/modules";
import { settings } from "../../lib/modules";
import { getDashboardPath } from "../../lib/utils";
import { type Dashboard, useStore } from "../../store/useStore";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/Command";

export const CommandMenu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const { commandMenuOpen, setCommandMenuOpen, user } = useStore();

  const { data: dashboards } = useQuery<Dashboard[]>({
    queryKey: ["fetchDashboards"],
    queryFn: () => fetchDashboards(user),
  });

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setCommandMenuOpen(!commandMenuOpen);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setCommandMenuOpen, commandMenuOpen]);

  const highlightSubmatches = (text: string, query?: string) => {
    const lowerQuery = query.toLowerCase();

    if (!query) {
      return text;
    }

    return text?.split("").map((letter) => {
      if (lowerQuery.includes(letter.toLowerCase())) {
        return <span className="text-highlight">{letter}</span>;
      }
      return letter;
    });
  };

  const handleSelect = (dashboard: Dashboard) => {
    navigate(getDashboardPath(dashboard));
    setCommandMenuOpen(false);
  };

  return (
    <>
      <CommandDialog open={commandMenuOpen} onOpenChange={setCommandMenuOpen}>
        <CommandInput
          placeholder={t("searchPlaceholder")}
          value={searchQuery}
          onValueChange={(value) => setSearchQuery(value)}
        />
        <CommandList>
          <CommandEmpty>{t("noResultsFound")}</CommandEmpty>

          <CommandGroup>
            {dashboards?.map((dashboard) => (
              <CommandItem
                key={dashboard.dashboard_id || dashboard._id}
                value={dashboard.name}
                onSelect={() => handleSelect(dashboard)}
              >
                <div className="flex flex-col text-foreground">
                  <span
                    key={dashboard.dashboard_id || dashboard._id}
                    className="text-base"
                  >
                    {highlightSubmatches(dashboard.name, searchQuery)}
                  </span>
                  {searchQuery && (
                    <span className="text-sm text-secondary">
                      {dashboard.name}
                    </span>
                  )}
                </div>
              </CommandItem>
            ))}

            {settings.map((setting) => (
              <CommandItem
                key={setting.name}
                value={setting.name}
                onSelect={() => navigate(setting.path || "#")}
              >
                <div className="flex flex-col text-foreground">
                  <span className="text-base">
                    {highlightSubmatches(setting.name, searchQuery)}
                  </span>
                  {searchQuery && (
                    <span className="text-sm text-secondary">
                      {setting.name}
                    </span>
                  )}
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  );
};
