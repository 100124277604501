import i18n, { type InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

export const FALLBACK_LOCALE = "nb";
export const supportedLocales = ["en", "nb", "nn", "li"];
export type Locales = (typeof supportedLocales)[number];

const getOptions = (
  lang = FALLBACK_LOCALE,
  ns = "translation",
): InitOptions => {
  return {
    supportedLngs: supportedLocales,
    fallbackLng: FALLBACK_LOCALE,
    lng: lang,
    ns,
  };
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (lang: string, ns: string) => import(`/src/locales/${lang}/${ns}.json`),
    ),
  )
  .init({
    ...getOptions(),
  });

export const changeLocale = (lang: Locales) => {
  i18n.changeLanguage(lang);
};
