import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  type MyPageRequest,
  type Widget,
  fetchWidgetsFn,
  saveOrUpdateWidgetsFn,
} from "../api/widgets";
import { useStore } from "../store/useStore";

export const useWidgets = (id?: string) => {
  const queryClient = useQueryClient();
  const { user, organization } = useStore();

  // TODO: handle authentication
  const { data, error, isLoading } = useQuery<MyPageRequest>({
    queryKey: ["widgets", organization.id, user.id],
    queryFn: () => fetchWidgetsFn(organization.id, user.id),
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 1,
  });

  const { tempWidgets, setTempWidgets } = useStore();

  const widgets = useMemo(() => {
    if (tempWidgets) {
      return tempWidgets;
    }
    return data?.widgets;
  }, [data, tempWidgets]);

  const widget = useMemo(() => {
    if (tempWidgets) {
      return tempWidgets.find((widget) => widget.id === id);
    }
    return widgets?.find((widget) => widget.id === id);
  }, [widgets, id, tempWidgets]);

  const mutation = useMutation({
    mutationFn: saveOrUpdateWidgetsFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["widgets"] });
    },
    onError: (error) => {
      mutation.reset();
      console.error("Failed to save widgets:", error);
    },
  });

  const saveWidgets = async (widgetsToSave: Widget[]) => {
    await mutation.mutateAsync({
      id: data?.id,
      userId: user.id,
      companyId: organization.id,
      widgets: widgetsToSave,
    });
    setTempWidgets(null);
  };

  return {
    widgets,
    widget,
    error,
    isLoading,
    saveWidgets,
  };
};
