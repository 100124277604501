import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { StrictMode } from "react";
import { HashRouter } from "react-router-dom";
import { App } from "./App";
import { useLDProvider } from "./hooks/useLDProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: Boolean(process.env.APP_ENV),
      refetchOnReconnect: Boolean(process.env.APP_ENV),
      retry: false,
    },
  },
});

export const AppRouter = () => {
  const LDProvider = useLDProvider();

  return (
    <StrictMode>
      <HashRouter>
        <QueryClientProvider client={queryClient}>
          {LDProvider ? (
            <LDProvider>
              <App />
            </LDProvider>
          ) : (
            <App />
          )}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </HashRouter>
    </StrictMode>
  );
};
