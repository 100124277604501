import type React from "react";

export const NoWidgetIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={64}
    height={64}
    fill={"#ADAFB5"}
    {...props}
  >
    <g fillRule="nonzero" opacity={0.5}>
      <path d="M3.443 29.566h20.66a3.44 3.44 0 0 0 3.444-3.443V5.463a3.44 3.44 0 0 0-3.443-3.444H3.444A3.44 3.44 0 0 0 0 5.462v20.66a3.44 3.44 0 0 0 3.443 3.444zM3.443 64h20.66a3.44 3.44 0 0 0 3.444-3.443v-20.66a3.44 3.44 0 0 0-3.443-3.444H3.444A3.44 3.44 0 0 0 0 39.896v20.66A3.44 3.44 0 0 0 3.443 64zM37.877 64h20.66a3.44 3.44 0 0 0 3.444-3.443v-20.66a3.44 3.44 0 0 0-3.443-3.444h-20.66a3.44 3.44 0 0 0-3.444 3.443v20.66A3.44 3.44 0 0 0 37.877 64zM62.99 13.358 50.642 1.01a3.44 3.44 0 0 0-4.869 0L33.425 13.358a3.44 3.44 0 0 0 0 4.869l12.348 12.348a3.44 3.44 0 0 0 4.87 0L62.99 18.227a3.44 3.44 0 0 0 0-4.87z" />
    </g>
  </svg>
);
