import { zodResolver } from "@hookform/resolvers/zod";
import { type Control, useForm } from "react-hook-form";
import { z } from "zod";
import { useWidgets } from "../../../hooks/useWidgets";
import { useStore } from "../../../store/useStore";
import { Form } from "../../ui/Form";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../ui/Sheet";
import { useEffect } from "react";
import { SizeRadioGroup } from "./SizeRadioGroup";
import { WidgetNameInput } from "./WidgetNameInput";
import { HideWidgetSwitch } from "./HideWidgetSwitch";
import { Button } from "@sikrias/samsvar-design-system";
import { useTranslation } from "react-i18next";

const maxLength = 24;
const minLength = 1;

const formSchema = z.object({
  title: z
    .string()
    .max(maxLength, "Must be maximum 24 characters long.")
    .min(minLength, "Must be minimum 1 character long."),
  expanded: z.boolean(),
  visible: z.boolean(),
});

type FormData = z.infer<typeof formSchema>;
export type EditWidgetSheetControlType = Control<FormData>;

export const EditWidgetSheet = () => {
  const { isEditingWidgetId, setIsEditingWidgetId, updateTempWidget } =
    useStore();

  const { widget } = useWidgets(isEditingWidgetId);

  const { t } = useTranslation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: widget?.title || "",
      expanded: widget?.expanded,
      visible: widget?.visible,
    },
  });

  useEffect(() => {
    if (widget) {
      form.reset({
        title: widget.title || "",
        expanded: widget.expanded ?? false,
        visible: widget.visible ?? false,
      });
    }
  }, [widget, form]);

  if (!widget) {
    return null;
  }

  const title = form.watch("title");

  function onSubmit(values: z.infer<typeof formSchema>) {
    updateTempWidget(isEditingWidgetId, (widget) => {
      widget.title = values.title;
      widget.expanded = values.expanded;
      widget.visible = values.visible;
    });
    setIsEditingWidgetId(null);
  }

  return (
    <div>
      <Sheet
        open={Boolean(isEditingWidgetId)}
        onOpenChange={() => setIsEditingWidgetId(null)}
      >
        <SheetContent
          className="w-full sm:w-[568px] max-h-screen flex flex-col"
          classNameCloseButton="text-foreground right-6 top-5"
          data-testid="edit-widget-sheet"
        >
          <SheetHeader className="bg-background">
            <SheetTitle className="text-foreground px-6 py-8">
              {t("editWidget")}
            </SheetTitle>
          </SheetHeader>

          <div className="flex flex-col px-6 py-4 h-full flex-grow">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8 h-full flex flex-col justify-between"
              >
                <div>
                  <div>
                    <WidgetNameInput
                      control={form.control}
                      widget={widget}
                      title={title}
                      maxLength={maxLength}
                    />
                  </div>

                  <div className="mt-8">
                    <SizeRadioGroup control={form.control} />
                  </div>
                  <div className="mt-8">
                    <HideWidgetSwitch control={form.control} />
                  </div>
                </div>

                <div className="flex justify-end">
                  <Button
                    variant="underline"
                    onClick={() => setIsEditingWidgetId(null)}
                    className="mr-4"
                  >
                    {t("cancel")}
                  </Button>

                  <Button type="submit" variant="primary">
                    {t("save")}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};
