import React from "react";
import { cn } from "../../lib/utils";

interface NoActionsIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

export const NoActionsIcon: React.FC<NoActionsIconProps> = ({
  color = "#C2C2C3",
  className,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 28"
      width={21}
      height={28}
      fill={color}
      className={cn("transition-transform duration-200 ease-in-out", className)}
      {...props}
    >
      <path
        d="M21 6.669V7h-7V0h.331c.348 0 .682.138.928.384l5.357 5.357c.246.246.384.58.384.928zM13.562 8.75H21v17.938c0 .724-.588 1.312-1.313 1.312H1.313A1.312 1.312 0 0 1 0 26.687V1.313C0 .589.588 0 1.313 0H12.25v7.438c0 .721.59 1.312 1.313 1.312zm3.571 6.001-1.54-1.553a.656.656 0 0 0-.928-.004l-5.8 5.752-2.514-2.534a.656.656 0 0 0-.928-.004l-1.553 1.54a.656.656 0 0 0-.003.928l4.517 4.554c.255.258.67.26.928.004l7.818-7.755a.656.656 0 0 0 .003-.928z"
        fillRule="nonzero"
      />
    </svg>
  );
};
