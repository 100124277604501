import { useWidgets } from "../../../hooks/useWidgets";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/Tabs";
import {
  ListItem,
  WidgetStatisticsBox,
} from "../Templates/WidgetStatisticsBox";
import { WidgetTemplate } from "../Templates/WidgetTemplate";
import { InjuryChart } from "./InjuryChart";

interface InjuryWidgetProps {
  id: string;
}

export const InjuryWidget = ({ id }: InjuryWidgetProps) => {
  const { widget } = useWidgets(id);

  if (!widget) {
    console.info("InjuryWidget: widget not found id:", id);
    return null;
  }

  const { title, visible, expanded } = widget;

  return (
    <WidgetTemplate
      id={id}
      title={title}
      pageLink={"fm"}
      visible={visible}
      data-testid="injury-widget"
    >
      <InjuryStatistics />
      <InjuryChart />

      {expanded && (
        <Tabs defaultValue="ongoing" className="w-full" data-testid="tabs">
          <TabsList>
            <TabsTrigger value="ongoing" data-testid="injury-ongoing">
              Ongoing cases
            </TabsTrigger>
            <TabsTrigger value="actions">Actions</TabsTrigger>
          </TabsList>

          <TabsContent value="ongoing">
            {Array.from({ length: 5 }).map((_, i) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: suppressed because it is dummy data
              <ListItem key={i} value={"Ongoing"} />
            ))}
          </TabsContent>
          <TabsContent value="actions">
            {Array.from({ length: 5 }).map((_, i) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: suppressed because it is dummy data
              <ListItem key={i} value={"Action"} />
            ))}
          </TabsContent>
        </Tabs>
      )}
    </WidgetTemplate>
  );
};

const InjuryStatistics = () => {
  return (
    <div className="flex justify-around border rounded-lg border-gray-200">
      <WidgetStatisticsBox value={42} label="Ongoing cases" />
      <WidgetStatisticsBox
        className="border-x-2"
        value={4}
        label="Urgent cases"
      />
      <WidgetStatisticsBox value={16} label="Actions" />
    </div>
  );
};
