import { useStore } from "../../store/useStore";

export const Announcements = () => {
  const { organization } = useStore();

  const announcements = [
    {
      id: 1,
      title: "Title",
      description:
        "A brief description of the news that is important to read for everyone in the company. This description can be long.",
    },
    {
      id: 2,
      title: "A bit longer title",
      description:
        "A brief description of the news that is important to read for everyone in the company. This description can be long. A brief description of the news that is important to read for everyone in the company. This description can be long.",
    },
  ];

  if (announcements.length === 0) {
    return null;
  }

  return (
    <div className="bg-chartBlue5 w-full rounded-md p-6 mb-4">
      <h1 className="text-xl">
        {organization?.name
          ? `Updates from ${organization.name}`
          : "Company updates"}
      </h1>
      {announcements.map((announcement) => (
        <div key={announcement.id} className="my-4">
          <h2 className="text-base font-bold">{announcement.title}</h2>
          <span className="text-sm">{announcement.description}</span>
        </div>
      ))}
    </div>
  );
};
