import { useWidgets } from "../../../hooks/useWidgets";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/Tabs";
import {
  ListItem,
  WidgetStatisticsBox,
} from "../Templates/WidgetStatisticsBox";
import { WidgetTemplate } from "../Templates/WidgetTemplate";
import { DocumentChart } from "./DocumentChart";

interface DocumentWidgetProps {
  id: string;
}

export const DocumentWidget = ({ id }: DocumentWidgetProps) => {
  const { widget } = useWidgets(id);

  return (
    <WidgetTemplate
      id={id}
      title={widget.title}
      pageLink={"document"}
      visible={widget.visible}
      data-testid="document-widget"
    >
      <DocumentStatistics />
      <DocumentChart />
      {widget.expanded && (
        <Tabs defaultValue="ongoing" className="w-full">
          <TabsList>
            <TabsTrigger value="ongoing">Ongoing cases</TabsTrigger>
            <TabsTrigger value="actions">Actions</TabsTrigger>
          </TabsList>
          <TabsContent value="ongoing">
            {Array.from({ length: 5 }).map((_, i) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: suppressed because it is dummy data
              <ListItem key={i} value="Ongoing" />
            ))}
          </TabsContent>
          <TabsContent value="actions">
            {Array.from({ length: 5 }).map((_, i) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: suppressed because it is dummy data
              <ListItem key={i} value="Action" />
            ))}
          </TabsContent>
        </Tabs>
      )}
    </WidgetTemplate>
  );
};

const DocumentStatistics = () => {
  return (
    <div className="flex justify-around border rounded-lg border-gray-200">
      <WidgetStatisticsBox value={26} label="Published" />
      <WidgetStatisticsBox className="border-x-2" value={3} label="Viewed" />
      <WidgetStatisticsBox value={4} label="Favorited" />
    </div>
  );
};
