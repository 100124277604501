import React from "react";
import { cn } from "../../lib/utils";

interface ChevronIconProps extends React.SVGProps<SVGSVGElement> {
  direction?: "up" | "right" | "down" | "left";
}

export const ChevronIcon: React.FC<ChevronIconProps> = ({
  direction = "down",
  className,
  ...props
}) => {
  const rotationClasses = {
    up: "rotate-180",
    right: "-rotate-90",
    down: "rotate-0",
    left: "rotate-90",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 7"
      width={12}
      height={7}
      fill="#353637"
      className={cn(
        "transition-transform duration-200 ease-in-out",
        rotationClasses[direction],
        className,
      )}
      {...props}
    >
      <path
        d="m11.904.645-.536-.548a.321.321 0 0 0-.46 0L6 5.092 1.093.097a.321.321 0 0 0-.461 0L.096.645a.337.337 0 0 0 0 .47L5.77 6.903c.127.13.333.13.46 0l5.674-5.788a.337.337 0 0 0 0-.47z"
        fillRule="nonzero"
      />
    </svg>
  );
};
