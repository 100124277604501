import { Header } from "../Header/Header";
import { ScrollArea } from "./ScrollArea";

export const Layout = ({ children }) => {
  return (
    <div className="bg-neutral-100 text-foreground font-sans h-screen flex flex-col">
      <Header />
      <ScrollArea className="w-full h-[calc(100vh-4rem)]">
        <div className="flex flex-col justify-center items-center">
          <div className="w-10/12 xl:w-[1028px]">{children}</div>
        </div>
      </ScrollArea>
    </div>
  );
};
