import { useEffect } from "react";
import { useStore } from "../store/useStore";

export const useUserAndOrg = () => {
  const { setUser, setOrganization } = useStore();

  useEffect(() => {
    const ngStorageCurrent = localStorage.getItem("ngStorage-current");
    if (
      ngStorageCurrent &&
      JSON.parse(ngStorageCurrent).user &&
      JSON.parse(ngStorageCurrent).organization
    ) {
      const { user, organization } = JSON.parse(ngStorageCurrent);
      setUser(user);
      setOrganization(organization);
    } else {
      setUser({
        id: "user1",
        name: "Test User",
        primary_locale: "",
        permited_language: [],
        org_id: "",
        org_name: "",
        role: "",
        mfa: "",
        phone: "",
        email: "",
        principalId: "",
        avatar: "",
      });
      setOrganization({
        id: "company1",
        name: "Test Company",
        dashboards: [],
        country: "",
        org_language: "",
      });
    }
  }, [setUser, setOrganization]);
};
