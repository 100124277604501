import { Pie, PieChart } from "recharts";
import { Chart, type ChartConfig } from "./Chart";
import { Card, CardContent } from "./Card";

const chartConfig = {} satisfies ChartConfig;
const chartData = [{ type: "No cases", ncs: 1, fill: "#e4e3ea" }];

interface EmptyChartProps {
  legendTitle?: string;
  legendText?: string;
}
export const EmptyChart = ({ legendTitle, legendText }: EmptyChartProps) => (
  <Card className="flex flex-col border-none shadow-none">
    <CardContent className="flex p-0 justify-center">
      <div className="flex flex-col sm:flex-row">
        <Chart
          config={chartConfig}
          chartId="empty-pie-chart"
          className="mx-auto aspect-square min-h-[200px]"
        >
          <PieChart>
            <Pie
              data={chartData}
              dataKey="ncs"
              nameKey="type"
              innerRadius={50}
              strokeWidth={5}
              isAnimationActive={false}
            />
          </PieChart>
        </Chart>

        {legendTitle && legendText && (
          <div className="flex flex-col justify-center">
            <h3 className="font-bold mb-3">{legendTitle}</h3>
            <ul className="space-y-2 text-sm">
              <li key={"empty-chart"} className="flex items-center gap-2">
                <span
                  className="inline-block size-3 rounded-full"
                  style={{ backgroundColor: "#e4e3ea" }}
                />
                <span>{legendText}</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </CardContent>
  </Card>
);
