import type React from "react";

export const ErrorIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 40"
    width={20}
    height={20}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <title>Error Icon</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M17.848 2.435c1.846-3.247 6.458-3.247 8.304 0l17.199 30.26C45.196 35.943 42.889 40 39.199 40H4.801c-3.69 0-5.997-4.058-4.152-7.304l17.2-30.261z"
        fill="#E4E3EA"
      />
      <path
        d="M22.319 10.032c1.056 0 1.913.953 1.913 2.13v9.935c0 1.176-.857 2.129-1.913 2.129-1.057 0-1.913-.953-1.913-2.13v-9.935c0-1.176.856-2.129 1.913-2.129zM22 33.84c1.585 0 2.87-1.3 2.87-2.904 0-1.603-1.285-2.903-2.87-2.903-1.585 0-2.87 1.3-2.87 2.903 0 1.604 1.285 2.904 2.87 2.904z"
        fill="#ADAFB5"
      />
    </g>
  </svg>
);
