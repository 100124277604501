export const env = (() => {
  switch (process.env.APP_ENV) {
    case "PRODUCTION":
      return "prod";
    case "DEVELOPMENT":
      return "demo";
    default:
      return "local";
  }
})();

export const getJwtCookie = () => {
  const cookies = document.cookie.split(";");
  const jwtCookie = cookies.find((cookie) => cookie.includes("idToken"));
  const value = jwtCookie?.split("=")[1];

  return value;
};

export const getXSelectedOrg = () => {
  const ngStorageCurrent = localStorage.getItem("ngStorage-current");
  if (ngStorageCurrent && JSON.parse(ngStorageCurrent).user) {
    const { user } = JSON.parse(ngStorageCurrent);

    return {
      org_id: user.org_id,
      role: user.role,
      primary_locale: user.primary_locale,
    };
  }
  return null;
};
