import type { User } from "../store/useStore";
import { env } from "./utils";

const getJwtCookie = () => {
  const cookies = document.cookie.split(";");
  const jwtCookie = cookies.find((cookie) => cookie.includes("idToken"));
  const value = jwtCookie?.split("=")[1];
  return value;
};

export const fetchDashboards = async (user: User) => {
  const selectedOrg = {
    org_id: user?.org_id,
    role: user?.role,
    primary_locale: user?.primary_locale,
  };

  if (user?.id === "user1" && user?.name === "Test User") {
    // Do not fetch dashboards for test user
    return;
  }
  const response = await fetch(`/${env}/general/getAllDashboards`, {
    headers: {
      Authorization: getJwtCookie(),
      "X-Selected-Org": JSON.stringify(selectedOrg),
    },
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
};
