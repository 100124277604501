import { NoWidgetIcon } from "../../assets/icons/NoWidgetIcon";
import { useTranslation } from "react-i18next";

export const NoAvailableWidgets = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <NoWidgetIcon className="mb-12" />

      <h2 className="font-bold text-xl text-foreground mb-6">
        {t("noWidgetsAvailable")}
      </h2>
      <p className="text-center font-normal text-base">
        {t("noWidgetsDescription")}
      </p>
    </div>
  );
};
