import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { AppRouter } from "./AppRouter";

import "!style-loader!css-loader!postcss-loader!tailwindcss/tailwind.css";
import "./index.css";
import "@fontsource/open-sans";
import "@sikrias/samsvar-design-system/dist/styles.css";

let removedStylesheets: HTMLLinkElement[] = [];

const removeAllStylesheets = () => {
  const linkTags = Array.from(
    document.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]'),
  );
  removedStylesheets = linkTags.map(
    (link) => link.cloneNode(true) as HTMLLinkElement,
  );
  for (const link of linkTags) {
    link.parentNode?.removeChild(link);
  }
};

const restoreStylesheets = () => {
  const head = document.head;
  for (const link of removedStylesheets) {
    if (link) {
      head.appendChild(link);
    }
  }
  removedStylesheets = [];
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: AppRouter,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
  renderType: "createRoot",
});

// https://sikri.atlassian.net/wiki/spaces/Samsvar/pages/76054529/CSS+loading+unloading+-+Single-SPA
const originalMount = lifecycles.mount;
lifecycles.mount = async (...args) => {
  removeAllStylesheets();
  await originalMount(...args);
};

const originalUnmount = lifecycles.unmount;
lifecycles.unmount = async (...args) => {
  await originalUnmount(...args);
  restoreStylesheets();
};

export const { bootstrap, mount, unmount } = lifecycles;
