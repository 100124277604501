export const Bars = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.732 1.618H.268A.269.269 0 0 1 0 1.348V.27C0 .12.12 0 .268 0h19.464c.148 0 .268.12.268.27v1.078c0 .15-.12.27-.268.27zm0 7.191H.268A.269.269 0 0 1 0 8.539V7.461c0-.15.12-.27.268-.27h19.464c.148 0 .268.12.268.27v1.078c0 .15-.12.27-.268.27zm0 7.191H.268A.269.269 0 0 1 0 15.73v-1.078c0-.15.12-.27.268-.27h19.464c.148 0 .268.12.268.27v1.078c0 .15-.12.27-.268.27z"
      fill="#999BA2"
      fillRule="nonzero"
    />
  </svg>
);
