import Logo from "../../assets/SamsvarLogo.svg";

export const SamsvarLogo = () => {
  return (
    <img
      className="w-24 h-16"
      alt="Samsvar Logo"
      src={Logo as unknown as string}
      width={0}
      height={0}
    />
  );
};
