import React from "react";

export const NorwegianFlag = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.5"
      height="16"
      viewBox="0 0 1100 800"
      {...props}
    >
      <rect width="1100" height="800" fill="#ef2b2d" />
      <rect width="200" height="800" x="300" fill="white" />
      <rect width="1100" height="200" y="300" fill="white" />
      <rect width="100" height="800" x="350" fill="#002868" />
      <rect width="1100" height="100" y="350" fill="#002868" />
    </svg>
  );
};
