import { TaskListItem } from "../Templates/TaskListItem";
import dayjs from "dayjs";
import type { WidgetData, WidgetDataComponent } from "../../../api/widgets";
import { useNavigate } from "react-router-dom";
import { NoActionsIcon } from "../../../assets/icons/NoActionsIcon";

interface NonconformityActionsProps {
  data: WidgetData;
  actionUrl?: string;
}

export const NonconformityActions = ({
  data,
  actionUrl,
}: NonconformityActionsProps) => {
  const navigate = useNavigate();

  if (!data || data.widgetDataComponent.length === 0) {
    return (
      <div className="flex justify-center items-center">
        <div className="flex flex-col items-center">
          <NoActionsIcon className="my-4" />
          <span className="text-secondary text-sm mb-4">No actions</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h3 className="text-base font-bold my-2">Actions</h3>
      {data?.widgetDataComponent
        .slice(0, 5)
        .map((action: WidgetDataComponent) => (
          <TaskListItem
            onClick={() => {
              actionUrl && navigate(`${actionUrl}/${action.reportId}`);
            }}
            className={actionUrl ? "hover:cursor-pointer" : ""}
            taskName={action.name}
            dueDate={dayjs(action.dueDate)}
            key={action.name}
          />
        ))}
    </div>
  );
};
