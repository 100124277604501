import type React from "react";

export const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={20}
    height={20}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <title>Home Icon</title>
    <path
      d="M3 20V10.065C3 9.45242 3.28074 8.87362 3.76182 8.49437L10.7618 2.97609C11.488 2.40361 12.512 2.40361 13.2382 2.97609L20.2382 8.49437C20.7193 8.87362 21 9.45243 21 10.065V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
