import { useTranslation } from "react-i18next";
import { useStore } from "../../store/useStore";

export const Greeting = () => {
  const { t } = useTranslation();
  const { user } = useStore();
  const date = new Date();

  const getGreetingKey = (): string => {
    const hour = date.getHours();

    if (hour >= 5 && hour < 12) {
      return "goodMorning";
    }
    if (hour >= 12 && hour < 17) {
      return "goodAfternoon";
    }
    if (hour >= 17 && hour < 22) {
      return "goodEvening";
    }
    return "goodNight";
  };

  return (
    <h1 className="font-medium text-2xl">
      {t(getGreetingKey())}
      {user?.name && ","} {user?.name}
    </h1>
  );
};
