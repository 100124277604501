import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/Form";
import { Input } from "../../ui/Input";
import type { Widget } from "../../../api/widgets";
import type { EditWidgetSheetControlType } from "./EditWidgetSheet";
import { RequiredIndicator } from "../../../assets/icons/RequiredIndicator";
import { useTranslation } from "react-i18next";

interface WidgetNameInputProps {
  control: EditWidgetSheetControlType;
  maxLength: number;
  widget: Widget;
  title: string;
}

export const WidgetNameInput = ({
  control,
  widget,
  title,
  maxLength,
}: WidgetNameInputProps) => {
  const { t } = useTranslation();
  return (
    <FormField
      control={control}
      name="title"
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            <div className="flex justify-between my-2">
              <div>
                <span className="text-md font-semibold">{t("name")}</span>{" "}
                <RequiredIndicator />
              </div>
              <span className="text-gray-400">{`${title.length}/${maxLength}`}</span>
            </div>
          </FormLabel>
          <FormControl>
            <Input
              className="border-black border-2 rounded-sm"
              placeholder={widget.title}
              maxLength={maxLength}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
