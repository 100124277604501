import React from "react";

export const LithuanianFlag = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.67"
      height="16"
      viewBox="0 0 1000 600"
      {...props}
    >
      <path fill="#c1272d" d="M0 0h1000v600H0z" />
      <path fill="#006a44" d="M0 0h1000v400H0z" />
      <path fill="#fdb913" d="M0 0h1000v200H0z" />
    </svg>
  );
};
