import dayjs, { type Dayjs } from "dayjs";
import { cn } from "../../../lib/utils";
import { Separator } from "../../ui/Separator";

interface TaskListItemProps {
  taskName: string;
  dueDate?: Dayjs;
  onClick?: () => void;
  className?: string;
}

export const TaskListItem = ({
  taskName,
  dueDate,
  onClick,
  className,
}: TaskListItemProps) => {
  const isOverdue = (dueDate: Dayjs) => {
    const today = dayjs();
    // Set to end of the day
    today.endOf("day");
    return dueDate <= today;
  };

  return (
    <div className={cn("flex flex-col", className)} onClick={onClick}>
      <span className="text-sm font-normal">{taskName}</span>
      <div>
        {dueDate && (
          <>
            <span
              className={cn(
                "text-sm",
                isOverdue(dueDate) ? "text-red-700" : "text-secondary",
              )}
            >
              {dueDate.format("DD.MM.YYYY")}
            </span>
          </>
        )}
      </div>
      <Separator className="my-4" />
    </div>
  );
};
