import { FaCompressAlt, FaExpandAlt } from "react-icons/fa";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/Form";
import { RadioGroup, RadioGroupItem } from "../../ui/RadioGroup";
import type { EditWidgetSheetControlType } from "./EditWidgetSheet";
import { cn } from "../../../lib/utils";
import type { ControllerRenderProps } from "react-hook-form";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface SizeRadioGroupProps {
  control: EditWidgetSheetControlType;
}

export const SizeRadioGroup = ({ control }: SizeRadioGroupProps) => {
  const { t } = useTranslation();
  return (
    <FormField
      control={control}
      name="expanded"
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            <div className="flex justify-between my-2">
              <span className="text-md font-semibold">{t("size")}</span>
            </div>
          </FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              defaultValue={field.value ? "true" : "false"}
              className="grid-cols-2"
            >
              <RadioItem
                value="true"
                isSelected={String(field.value) === "true"}
                label={t("fullView")}
                icon={<FaExpandAlt />}
                field={field}
              />
              <RadioItem
                value="false"
                isSelected={String(field.value) === "false"}
                label={t("compactView")}
                icon={<FaCompressAlt />}
                field={field}
              />
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

interface RadioItemProps {
  value: string;
  isSelected: boolean;
  label: string;
  icon: ReactNode;
  field: ControllerRenderProps<
    {
      title?: string;
      expanded?: boolean;
      visible?: boolean;
    },
    "expanded"
  >;
}

const RadioItem = ({
  value,
  isSelected,
  label,
  icon,
  field,
}: RadioItemProps) => (
  <FormItem
    className={cn(
      "flex items-center border-2 p-2 rounded w-full hover:cursor-pointer",
      isSelected
        ? "border-blue-950 bg-hoverBackground text-foreground"
        : "border-gray-400",
      "hover:border-blue-950 hover:bg-hoverBackground",
    )}
  >
    <FormControl>
      <RadioGroupItem
        value={value}
        checked={isSelected}
        onChange={() => field.onChange(value)}
        className="cursor-pointer rounded-full border-foreground text-foreground"
      >
        <div className="flex items-center justify-center">{icon}</div>
      </RadioGroupItem>
    </FormControl>
    <button
      type="button"
      className="flex items-center justify-between w-full ml-3"
      onClick={() => field.onChange(value)}
    >
      <FormLabel
        className={cn(
          "font-normal flex items-center justify-between w-full text-foreground hover:cursor-pointer",
        )}
      >
        <span>{label}</span>
        <span className={cn(isSelected ? "text-blue-950" : "text-gray-400")}>
          {icon}
        </span>
      </FormLabel>
    </button>
  </FormItem>
);
