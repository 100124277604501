import { type Dispatch, type SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/Popover";
import { Calendar } from "../ui/Calendar";
import type { DateRange } from "react-day-picker";
import { cn } from "../../lib/utils";
import { Button } from "@sikrias/samsvar-design-system";
import { TimeFilterItem } from "../Header/TimeFilter";

export function DatePickerWithRangePopover({
  className,
  date,
  setDate,
  onCustomSelect,
  selected,
}: {
  className?: string;
  date?: DateRange;
  setDate: Dispatch<SetStateAction<DateRange | undefined>>;
  onCustomSelect: () => void;
  selected?: boolean;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOkClick = () => {
    setDate(date);
    onCustomSelect();
    setOpen(false);
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <TimeFilterItem selected={selected}>{t("custom")}</TimeFilterItem>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="center">
          <Calendar
            initialFocus={true}
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
          <div className="flex justify-end">
            <Button
              className="mx-4 mb-2"
              variant="secondary"
              onClick={handleOkClick}
            >
              Ok
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
