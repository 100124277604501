import { TrendingUp } from "lucide-react";
import React from "react";
import { Label, Pie, PieChart } from "recharts";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/Card";
import {
  Chart,
  type ChartConfig,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/Chart";
const chartData = [
  { type: "accident", injuries: 20, fill: "var(--chart-blue-1)" },
  { type: "illness", injuries: 10, fill: "var(--chart-blue-2)" },
  { type: "minor injury", injuries: 8, fill: "var(--chart-blue-3)" },
  { type: "injury", injuries: 4, fill: "var(--chart-blue-4)" },
];

const chartConfig = {
  total: {
    label: "Total",
  },
  accident: {
    label: "Accidents",
    color: "red",
  },
  illness: {
    label: "Illnesses",
    color: "blue",
  },
  minorInjury: {
    label: "Minor Injuries",
    color: "green",
  },
  injury: {
    label: "Injuries",
    color: "gray",
  },
} satisfies ChartConfig;

export const InjuryChart = () => {
  const totalInjuries = React.useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.injuries, 0);
  }, []);

  return (
    <Card className="flex flex-col border-none">
      <CardHeader className="items-center pb-0">
        <CardTitle className="text-lg">Injuries by type</CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <Chart
          config={chartConfig}
          chartId="injury-pie-chart"
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel={true} />}
            />
            <Pie
              data={chartData}
              dataKey="injuries"
              nameKey="type"
              innerRadius={60}
              strokeWidth={5}
              isAnimationActive={false}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {totalInjuries.toLocaleString()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Injuries
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
          </PieChart>
        </Chart>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <div className="flex items-center gap-2 leading-none">
          Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
        </div>
      </CardFooter>
    </Card>
  );
};
