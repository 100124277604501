// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --chart-blue-1: #4a86e8;
  --chart-blue-2: #1f77b4;
  --chart-blue-3: #4682b4;
  --chart-blue-4: #7fbedf;
  --chart-blue-5: #c6e2f0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/charts.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,uBAAuB;EACvB,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":[":root {\n  --chart-blue-1: #4a86e8;\n  --chart-blue-2: #1f77b4;\n  --chart-blue-3: #4682b4;\n  --chart-blue-4: #7fbedf;\n  --chart-blue-5: #c6e2f0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
