import type { WidgetData, WidgetDataComponent } from "../../../api/widgets";
import { WidgetStatisticsBox } from "../Templates/WidgetStatisticsBox";

interface NonconformityStatisticsProps {
  data: WidgetData;
}

export const NonconformityStatistics = ({
  data,
}: NonconformityStatisticsProps) => {
  if (!data || data.widgetDataComponent.length === 0) {
    return (
      <div className="flex flex-col sm:flex-row justify-around border rounded-lg border-gray-200">
        <WidgetStatisticsBox key={"empty-1"} value={0} label="Ongoing cases" />

        <WidgetStatisticsBox
          key={"empty-2"}
          value={0}
          label="Urgent cases"
          className={"sm:border-x-[1px]"}
        />

        <WidgetStatisticsBox key={"empty-3"} value={0} label="Actions" />
      </div>
    );
  }

  return (
    <div className="flex flex-col sm:flex-row justify-around border rounded-lg border-gray-200">
      {data?.widgetDataComponent
        .slice(0, 3)
        .map((item: WidgetDataComponent, index: number) => (
          <WidgetStatisticsBox
            key={item.name}
            value={item.value}
            label={item.name}
            className={index === 1 ? "sm:border-x-[1px]" : ""}
          />
        ))}
    </div>
  );
};
