// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./assets/styles/charts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #000000;
  --accent: #e3e3e3;
  --hover-background:#f3f2f7;
  --border: #d9e4ed;

  --sikri-blue: #1b116e;
  --sikri-turquoise: #6bedb5;
}

body {
    overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;AAEnB;EACE,qBAAqB;EACrB,qBAAqB;EACrB,iBAAiB;EACjB,0BAA0B;EAC1B,iBAAiB;;EAEjB,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["@import \"./assets/styles/charts.css\";\n@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n:root {\n  --background: #ffffff;\n  --foreground: #000000;\n  --accent: #e3e3e3;\n  --hover-background:#f3f2f7;\n  --border: #d9e4ed;\n\n  --sikri-blue: #1b116e;\n  --sikri-turquoise: #6bedb5;\n}\n\nbody {\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
