import { Button } from "@sikrias/samsvar-design-system";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/Card";
import { ErrorIcon } from "../../../assets/icons/ErrorIcon";
import { useTranslation } from "react-i18next";

interface WidgetErrorTemplateProps {
  title: string;
  refetchCallback?: () => void;
}
export const WidgetErrorTemplate = ({
  title,
  refetchCallback,
}: WidgetErrorTemplateProps) => {
  const { t } = useTranslation();
  return (
    <Card className="bg-background flex flex-col">
      <CardHeader className="py-4 min-h-[60px]">
        <CardTitle className="text-base font-extrabold">
          <div className="flex justify-between items-center min-h-9">
            <div className="flex items-center relative">
              <span className="text-xl">{title}</span>
            </div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col justify-center items-center text-center pb-8">
        <ErrorIcon className="w-11 h-10" />

        <p className="my-6 w-3/5">{t("somethingWentWrong")}</p>

        <Button variant="secondary" size="md" onClick={refetchCallback}>
          {t("refresh")}{" "}
        </Button>
      </CardContent>
    </Card>
  );
};
