import { type ComponentType, useEffect, useState } from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

export const useLDProvider = () => {
  const [LDProvider, setLDProvider] = useState<ComponentType | null>(null);

  useEffect(() => {
    const initializeLDProvider = async () => {
      try {
        const LDProviderComponent = await asyncWithLDProvider({
          clientSideID: process.env.LD_CLIENT_ID,
          timeout: 5,
        });
        setLDProvider(() => LDProviderComponent);
      } catch (error) {
        console.error("Failed to initialize LaunchDarkly provider", error);
      }
    };
    initializeLDProvider();
  }, []);

  return LDProvider;
};
