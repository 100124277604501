import { useWidgets } from "../../../hooks/useWidgets";
import { WidgetTemplate } from "../Templates/WidgetTemplate";
import { NonconformityChart } from "./NonconformityChart";
import { NonconformityStatistics } from "./NonconformityStatistcs";
import { useQuery } from "@tanstack/react-query";
import { NonconformityActions } from "./NonconformityActions";
import { useStore } from "../../../store/useStore";
import { fetchWidgetDataFn, type WidgetData } from "../../../api/widgets";
import { WidgetErrorTemplate } from "../Templates/WidgetErrorTemplate";
import { WidgetLoadingTemplate } from "../Templates/WidgetLoadingTemplate";

interface NonconformityWidgetProps {
  id: string;
  actionUrl?: string;
}

export const NonconformityWidget = ({
  id,
  actionUrl,
}: NonconformityWidgetProps) => {
  const { widget } = useWidgets(id);
  const { customizeEnabled, currentTimeFilter } = useStore();

  const widgetDataQuery = useQuery<WidgetData[]>({
    queryKey: [
      `${widget?.widgetType}-widgetData`,
      currentTimeFilter.timeFilterRange.from,
      currentTimeFilter.timeFilterRange.to,
    ],
    queryFn: () =>
      fetchWidgetDataFn(
        widget?.widgetType,
        currentTimeFilter.timeFilterRange.from,
        currentTimeFilter.timeFilterRange.to,
      ),
    retry: false,
    enabled: !customizeEnabled,
  });

  const refetchCallback = () => {
    Promise.all([widgetDataQuery.refetch()]);
  };

  if (!widget) {
    console.info("Nonconformitywidget: widget not found id:", id);
    return null;
  }

  const { title, visible, expanded } = widget;

  if (widgetDataQuery.isLoading) {
    return <WidgetLoadingTemplate title={title} />;
  }

  if (widgetDataQuery.isError) {
    return (
      <WidgetErrorTemplate title={title} refetchCallback={refetchCallback} />
    );
  }

  const summaryData = widgetDataQuery.data?.find(
    (item) => item.widgetComponentType === "SUMMARY",
  );

  const pieChartData = widgetDataQuery.data?.find(
    (item) => item.widgetComponentType === "STATISTICS_PIE_CHART",
  );

  const documentListData = widgetDataQuery.data?.find(
    (item) => item.widgetComponentType === "DOCUMENT_LIST",
  );

  if (widgetDataQuery.isLoading) {
    return <div>Loading</div>;
  }

  return (
    <WidgetTemplate
      id={id}
      title={title}
      pageLink={"handler/general"}
      visible={visible}
      data-testid="nonconformity-widget"
      refetchCallback={refetchCallback}
      isRefetching={widgetDataQuery.isRefetching}
    >
      <NonconformityStatistics data={summaryData} />
      <NonconformityChart data={pieChartData} />

      {expanded && (
        <NonconformityActions data={documentListData} actionUrl={actionUrl} />
      )}
    </WidgetTemplate>
  );
};
