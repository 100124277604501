import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FALLBACK_LOCALE, changeLocale, supportedLocales } from "../../i18n";
import { useStore } from "../../store/useStore";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../ui/DropdownMenu";
import { UKFlag } from "../../assets/icons/flags/UKFlag";
import { LithuanianFlag } from "../../assets/icons/flags/LithuanianFlag";
import { NorwegianFlag } from "../../assets/icons/flags/NorwegianFlag";

export const LanguageDropdown = () => {
  const { t } = useTranslation();
  const { user, setUser, organization } = useStore();

  useEffect(() => {
    if (user?.primary_locale) {
      changeLocale(user.primary_locale);
    }
  }, [user?.primary_locale]);

  const handleLocaleChange = (lang: string) => {
    const updatedUser = { ...user, primary_locale: lang };
    setUser(updatedUser);

    localStorage.setItem(
      "ngStorage-current",
      JSON.stringify({ user: updatedUser, organization: organization }),
    );
    changeLocale(lang);
  };

  const selectedFlag = (() => {
    switch (user?.primary_locale) {
      case "en":
        return <UKFlag />;
      case "li":
        return <LithuanianFlag />;
      default:
        return <NorwegianFlag />;
    }
  })();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="hidden sm:flex mr-2 p-2 rounded-sm focus:outline-none hover:bg-hoverBackground">
        {selectedFlag}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-background">
        <DropdownMenuLabel>{t("switchLanguage")}</DropdownMenuLabel>
        <DropdownMenuRadioGroup
          value={user?.primary_locale || FALLBACK_LOCALE}
          onValueChange={handleLocaleChange}
        >
          {supportedLocales.map((lang) => (
            <DropdownMenuRadioItem
              key={lang}
              value={lang}
              className="hover:cursor-pointer"
            >
              {t(lang)}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
