import { Card, CardContent, CardHeader, CardTitle } from "../../ui/Card";
import { WidgetStatisticsBox } from "./WidgetStatisticsBox";
import { EmptyChart } from "../../ui/EmptyChart";
import { Skeleton } from "../../ui/Skeleton";
import { Separator } from "../../ui/Separator";

interface WidgetLoadingTemplateProps {
  title: string;
}
export const WidgetLoadingTemplate = ({
  title,
}: WidgetLoadingTemplateProps) => {
  return (
    <Card className="bg-background flex flex-col">
      <CardHeader className="py-4 min-h-[60px]">
        <CardTitle className="text-base font-extrabold">
          <div className="flex justify-between items-center min-h-9">
            <div className="flex items-center relative">
              <span className="text-xl">{title}</span>
            </div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex-grow">
        <div className="flex flex-col sm:flex-row justify-around border rounded-lg border-gray-200">
          <WidgetStatisticsBox key={"empty-1"} loading={true} />

          <WidgetStatisticsBox
            key={"empty-2"}
            loading={true}
            className={"sm:border-x-[1px]"}
          />

          <WidgetStatisticsBox key={"empty-3"} loading={true} />
        </div>

        <EmptyChart />

        <div>
          {/* <h3 className="text-base font-bold my-2">Actions</h3> */}

          <Skeleton className="h-12" />
          <Separator className="my-4" />
          <Skeleton className="h-12" />
          <Separator className="my-4" />
          <Skeleton className="h-12" />
          <Separator className="my-4" />
          <Skeleton className="h-12" />
          <Separator className="my-4" />
          <Skeleton className="h-12" />
        </div>
      </CardContent>
    </Card>
  );
};
