import "!style-loader!css-loader!postcss-loader!tailwindcss/tailwind.css";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Announcements } from "./components/Announcements/Announcements";
import { CustomizeGroup } from "./components/CustomizeGroup/CustomizeGroup";
import { Greeting } from "./components/Greeting/Greeting";
import { EditWidgetSheet } from "./components/Widgets/EditWidgetSheet/EditWidgetSheet";
import { Widgets } from "./components/Widgets/Widgets";
import { Layout } from "./components/ui/Layout";
import { Toaster } from "./components/ui/Toaster";
import { useUserAndOrg } from "./hooks/useUserAndOrg";
import "./i18n";
import "./index.css";

export function App() {
  const { mypage } = useFlags();
  useUserAndOrg();

  if (!mypage) {
    return null;
  }

  return (
    <Layout>
      <div className="flex items-center justify-between mt-8">
        <Greeting />
        <CustomizeGroup />
      </div>
      <div className="flex justify-center mt-4">
        <Announcements />
      </div>

      <div className="flex justify-center my-8">
        <Widgets />
      </div>

      <EditWidgetSheet />

      <Toaster />
    </Layout>
  );
}
