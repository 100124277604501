import type React from "react";
import { MdOutlineSecurity } from "react-icons/md";
import { RiAdminLine } from "react-icons/ri";
import { AnalyticsIcon } from "../assets/icons/AnalyticsIcon";
import { CalendarIcon } from "../assets/icons/CalendarIcon";
import { FolderIcon } from "../assets/icons/FolderIcon";
import { HomeIcon } from "../assets/icons/HomeIcon";
import { LayoutIcon } from "../assets/icons/LayoutIcon";
import { LockKeyIcon } from "../assets/icons/LockKeyIcon";
import { NonconformityIcon } from "../assets/icons/NonconformityIcon";
import { PatientIcon } from "../assets/icons/PatientIcon";
import { PolicyIcon } from "../assets/icons/PolicyIcon";
import { SecurityIcon } from "../assets/icons/SecurityIcon";
import { SettingsIcon } from "../assets/icons/SettingsIcon";
import { StethoscopeIcon } from "../assets/icons/StethoscopeIcon";
import { TaskIcon } from "../assets/icons/TaskIcon";
import { UserIcon } from "../assets/icons/UserIcon";
import { WorkflowSquareIcon } from "../assets/icons/WorkflowIcon";

export type ModuleInfo = {
  name: string;
  icon?: React.JSX.Element;
  path?: string;
};

export const modules: ModuleInfo[] = [
  {
    name: "Document handling",
    icon: <FolderIcon />,
    path: "/document",
  },
  {
    name: "Nonconformity",
    icon: <NonconformityIcon />,
    path: "/nonconform",
  },
  {
    name: "Information security",
    icon: <MdOutlineSecurity />,
  },

  {
    name: "Municipal duties",
    icon: <PolicyIcon />,
  },
  {
    name: "Checklists",
    icon: <TaskIcon />,
  },

  {
    name: "Privacy",
    icon: <LockKeyIcon />,
    path: "/main",
  },
  {
    name: "DPIA",
    icon: <SecurityIcon />,
  },
  {
    name: "Risk assessment",
    icon: <AnalyticsIcon />,
  },
  {
    name: "Calendar",
    icon: <CalendarIcon />,
    path: "/scheduler",
  },
  {
    name: "Injury",
    icon: <StethoscopeIcon />,
    path: "/fm",
  },
  {
    name: "Fall",
    icon: <PatientIcon />,
  },
  {
    name: "System management",
    icon: <LayoutIcon />,
    path: "/systems",
  },
  {
    name: "Workflow",
    icon: <WorkflowSquareIcon />,
    path: "/workflow",
  },
];

type SettingInfo = ModuleInfo;

export const settings: SettingInfo[] = [
  {
    name: "Admin",
    icon: <RiAdminLine />,
    path: "/admin",
  },
  {
    name: "Profile",
    icon: <UserIcon />,
    path: "/profile",
  },
  {
    name: "Settings",
    icon: <SettingsIcon />,
    path: "/settings",
  },
];

type DefaultInfo = ModuleInfo;

export const defaults: DefaultInfo[] = [
  {
    name: "Dashboard",
    icon: <HomeIcon />,
    path: "/#",
  },
];
