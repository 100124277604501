import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { fetchDashboards } from "../../api/modules";
import { Bars } from "../../assets/icons/Bars";
import { type ModuleInfo, defaults, settings } from "../../lib/modules";
import { getAndCapitalizeFirstLetter, getDashboardPath } from "../../lib/utils";
import { type Dashboard, useStore } from "../../store/useStore";
import { Avatar, AvatarFallback } from "../ui/Avatar";
import { ScrollArea } from "../ui/ScrollArea";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/Sheet";
import { Skeleton } from "../ui/Skeleton";

interface NavigationItemProps {
  navigationInfo: ModuleInfo;
}

const NavigationItem = ({ navigationInfo }: NavigationItemProps) => {
  return (
    <div>
      <SheetClose asChild={true}>
        <Link
          to={navigationInfo.path || "/#"}
          className="flex items-center gap-3 p-1 rounded-md py-3 transition-all opacity-80 hover:bg-hoverBackground"
        >
          <span className="size-5 flex justify-center items-center">
            {navigationInfo.icon ? (
              navigationInfo.icon
            ) : (
              <Skeleton className="w-5 h-5 rounded-sm" />
            )}
          </span>
          <span className="text-sm">{navigationInfo.name}</span>
        </Link>
      </SheetClose>
    </div>
  );
};

const NavigationItemSkeleton = () => {
  return (
    <div className="flex justify-center items-center gap-3 p-1 rounded-md py-3">
      <Skeleton className="w-5 h-5 rounded-sm" />
      <Skeleton className="w-64 h-5 rounded-sm" />
    </div>
  );
};

const NavigationItemSkeletons = () => {
  return (
    <>
      <NavigationItemSkeleton />
      <NavigationItemSkeleton />
      <NavigationItemSkeleton />
      <NavigationItemSkeleton />
      <NavigationItemSkeleton />
    </>
  );
};

interface DashboardNavigationItemProps {
  dashboard: Dashboard;
}

const DashboardNavigationItem = ({
  dashboard,
}: DashboardNavigationItemProps) => {
  return (
    <NavigationItem
      navigationInfo={{
        name: dashboard.name,
        path: getDashboardPath(dashboard),
        icon: (
          <img
            src={dashboard.icon || "images/landing/data-processors.png"}
            alt={`Icon for ${dashboard.name}`}
          />
        ),
      }}
    />
  );
};

export const Navigation = () => {
  const { user, organization } = useStore();

  const {
    data: dashboards,
    isLoading: isLoadingDashboards,
    isError,
    error,
  } = useQuery<Dashboard[]>({
    queryKey: ["fetchDashboards"],
    queryFn: () => fetchDashboards(user),
  });

  if (isError) {
    console.error("Failed to fetch dashboards:", error);
  }

  return (
    <nav className="h-5 mr-7">
      <Sheet>
        <SheetTrigger>
          <Bars className="text-2xl mr-4 text-gray-400" />
        </SheetTrigger>
        <SheetContent
          side="left"
          className="w-80 h-full"
          classNameCloseButton="right-4 top-7"
        >
          <SheetHeader>
            <SheetTitle className="flex items-center px-6 font-medium text-lg mt-6">
              {organization?.name && (
                <>
                  <Avatar className="h-8 w-8 mr-2">
                    <AvatarFallback className="bg-orange-500 text-white text-sm">
                      {getAndCapitalizeFirstLetter(user?.org_name) ||
                        getAndCapitalizeFirstLetter(organization?.name)}
                    </AvatarFallback>
                  </Avatar>
                  {user?.org_name || organization?.name}
                </>
              )}
            </SheetTitle>
          </SheetHeader>
          <div className="px-6 mt-3 text-foreground">
            <div className="border-b">
              {defaults?.map((defaultEntry) => (
                <NavigationItem
                  key={defaultEntry.name}
                  navigationInfo={defaultEntry}
                />
              ))}
            </div>
            <ScrollArea className="h-[calc(100vh-280px)]">
              {/* The height calc is to make space for settings below */}
              {isLoadingDashboards ? (
                <NavigationItemSkeletons />
              ) : (
                dashboards?.map((dashboard) => (
                  <DashboardNavigationItem
                    key={dashboard.dashboard_id || dashboard._id}
                    dashboard={dashboard}
                  />
                ))
              )}
            </ScrollArea>
            <div className="absolute bottom-2 w-full pr-12">
              {settings?.map((setting) => (
                <NavigationItem key={setting.name} navigationInfo={setting} />
              ))}
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </nav>
  );
};
