import type { Dayjs } from "dayjs";
import type { components } from "./schema";
import { getJwtCookie, getXSelectedOrg } from "./utils";

export type Widget = components["schemas"]["Widget"];
export type WidgetType = components["schemas"]["WidgetType"];
export type MyPageRequest = components["schemas"]["MyPage"];
export type WidgetData = components["schemas"]["WidgetData"];
export type WidgetDataComponent = components["schemas"]["WidgetDataComponent"];

interface ErrorDetail {
  message: string;
}

interface ErrorResponse {
  _links?: {
    self: { href: string; templated: boolean }[];
  };
  _embedded?: {
    errors: ErrorDetail[];
  };
  message?: string;
}

export const baseUrl = process.env.SERVICE_URL;

export const fetchWidgetsFn = async (companyId: string, userId: string) => {
  const response = await fetch(`${baseUrl}/my-page/${companyId}/${userId}`, {
    headers: {
      "X-Selected-Org": JSON.stringify(getXSelectedOrg()),
      Authorization: `Bearer ${getJwtCookie()}`,
    },
  });
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};

export const createWidget = async (widgetType: WidgetType): Promise<Widget> => {
  const response = await fetch(`${baseUrl}/widget/${widgetType}`);
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};

export const saveWidgetsFn = async (body: MyPageRequest) => {
  const response = await fetch(
    `${baseUrl}/my-page/${body.companyId}/${body.userId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwtCookie()}`,
      },
      body: JSON.stringify(body),
    },
  );
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};

export const updateWidgetsFn = async (body: MyPageRequest) => {
  const response = await fetch(
    `${baseUrl}/my-page/${body.companyId}/${body.userId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwtCookie()}`,
      },
      body: JSON.stringify(body),
    },
  );
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};

export const saveOrUpdateWidgetsFn = async (body: MyPageRequest) => {
  const method = body.id ? "PUT" : "POST";
  const response = await fetch(
    `${baseUrl}/my-page/${body.companyId}/${body.userId}`,
    {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwtCookie()}`,
      },
      body: JSON.stringify(body),
    },
  );
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};

export const fetchWidgetDataFn = async (
  widgetType: WidgetType,
  fromDate: Dayjs,
  toDate: Dayjs,
) => {
  const from_date = encodeURIComponent(fromDate.format());
  const to_date = encodeURIComponent(toDate.format());
  const response = await fetch(
    `${baseUrl}/widget-data/${widgetType}?from_date=${from_date}&to_date=${to_date}`,
    {
      method: "GET",
      headers: {
        "X-Selected-Org": JSON.stringify(getXSelectedOrg()),
        Authorization: `Bearer ${getJwtCookie()}`,
      },
    },
  );
  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json();

    throw new Error(
      `${response.status} ${errorResponse._embedded?.errors[0].message}`,
    );
  }
  return response.json();
};
