import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { type Dashboard, useStore } from "../store/useStore";
import type { Locales } from "../i18n";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getAndCapitalizeFirstLetter(string: string) {
  return string?.charAt(0).toUpperCase();
}

export const getDashboardPath = (dashboard: Dashboard) => {
  const locale = useStore.getState().user.primary_locale;
  switch (dashboard.dashboard_type) {
    case "url":
      return dashboard.url;
    case "systempage":
      return `/systems/${locale}/${dashboard.dashboard_id}`;
    case "general":
      return `/main/${dashboard.dashboard_id}/general`;
    case "municipality":
      return `/main/${dashboard.dashboard_id}/municipality`;
    default:
      return "/#";
  }
};

export const formatDate = (date: Date | undefined, locale: Locales) => {
  if (!date) {
    return null;
  }

  if (locale === "nb" || locale === "nn") {
    return date.toLocaleDateString("no", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  return date.toLocaleDateString("en", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
