import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useCallback, useMemo, useState } from "react";
import { useWidgets } from "../../hooks/useWidgets";
import { useStore } from "../../store/useStore";
import { Masonry } from "../ui/Masonry";
import { SortableWidget } from "./SortableWidget";
import { WidgetComponent } from "./WidgetComponent";
import { NoAvailableWidgets } from "./NoAvailableWidgets";

export const Widgets = () => {
  const { widgets } = useWidgets();
  const { setTempWidgets } = useStore();

  const { customizeEnabled } = useStore();
  const widgetIds = useMemo(() => {
    return widgets
      ?.filter((widget) => widget.visible || customizeEnabled) // Filter out hidden widgets unless customize mode is enabled
      .map((widget) => widget.id);
  }, [widgets, customizeEnabled]);

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor),
  );

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (!over) {
        return;
      }

      if (active.id !== over.id) {
        const oldIndex = widgets.findIndex((widget) => widget.id === active.id);
        const newIndex = widgets.findIndex((widget) => widget.id === over.id);
        const updatedWidgets = arrayMove([...widgets], oldIndex, newIndex);
        setTempWidgets(updatedWidgets);
      }

      setActiveId(null);
    },
    [widgets, setTempWidgets],
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  if (!widgetIds) {
    return (
      <div className="w-full sm:w-[440px] mt-10 sm:mt-40">
        <NoAvailableWidgets />
      </div>
    );
  }

  return customizeEnabled ? (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={widgetIds} strategy={rectSortingStrategy}>
        <Masonry>
          {widgetIds.map((id) => (
            <SortableWidget
              key={id}
              id={id}
              type={widgets.find((widget) => widget.id === id).widgetType}
            />
          ))}
        </Masonry>
      </SortableContext>
      <DragOverlay style={{ transformOrigin: "0 0 " }}>
        {activeId ? (
          <WidgetComponent
            id={activeId}
            isDragging={true}
            type={widgets.find((widget) => widget.id === activeId).widgetType}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  ) : (
    <Masonry>
      {widgetIds.map((id) => (
        <WidgetComponent
          key={id}
          id={id}
          type={widgets.find((widget) => widget.id === id).widgetType}
        />
      ))}
    </Masonry>
  );
};
