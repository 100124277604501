import { useTranslation } from "react-i18next";
import { FormControl, FormField, FormItem, FormLabel } from "../../ui/Form";
import { Switch } from "../../ui/Switch";
import type { EditWidgetSheetControlType } from "./EditWidgetSheet";

interface HideWidgetSwitchProps {
  control: EditWidgetSheetControlType;
}

export const HideWidgetSwitch = ({ control }: HideWidgetSwitchProps) => {
  const { t } = useTranslation();
  return (
    <FormField
      control={control}
      name="visible"
      render={({ field }) => (
        <FormItem className="flex mt-2 flex-col ">
          <FormLabel className="text-base font-semibold mb-2">
            {t("visibility")}
          </FormLabel>
          <div className="flex align-middle">
            <FormControl>
              <Switch
                checked={!field.value}
                onCheckedChange={(checked) => field.onChange(!checked)}
              />
            </FormControl>
            <span className="ml-6">{t("hideWidget")}</span>
          </div>
        </FormItem>
      )}
    />
  );
};
