import { type HTMLAttributes, forwardRef } from "react";
import type { Widget } from "../../api/widgets";
import { DocumentWidget } from "./DocumentWidget/DocumentWidget";
import { InjuryWidget } from "./InjuryWidget/InjuryWidget";
import { NonconformityWidget } from "./NonconformityWidget/NonconformityWidget";

export type WidgetProps = HTMLAttributes<HTMLDivElement> & {
  withOpacity?: boolean;
  isDragging?: boolean;
  id: string;
  type: Widget["widgetType"];
};

export const WidgetComponent = forwardRef<HTMLDivElement, WidgetProps>(
  ({ id, type, withOpacity, isDragging, style, ...props }, ref) => {
    const getWidget = () => {
      switch (type) {
        case "INJURY":
          return <InjuryWidget id={id} />;

        case "DOCUMENT_HANDLING":
          return <DocumentWidget id={id} />;

        case "NON_CONFORMITY_HANDLER":
          return <NonconformityWidget id={id} actionUrl="/handler/general" />;

        case "NON_CONFORMITY_REPORTER":
          return <NonconformityWidget id={id} actionUrl="/handler/general" />;

        default:
          return <InjuryWidget id={id} />;
      }
    };

    return (
      <div
        ref={ref}
        className="break-inside-avoid mb-8"
        data-testid="widget"
        style={{
          transformOrigin: "50% 50%",
          ...style,
        }}
        {...props}
      >
        {getWidget()}
      </div>
    );
  },
);
