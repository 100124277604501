import { Pie, PieChart } from "recharts";
import { Card, CardContent } from "../../ui/Card";
import {
  Chart,
  type ChartConfig,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/Chart";
import type { WidgetData, WidgetDataComponent } from "../../../api/widgets";
import { EmptyChart } from "../../ui/EmptyChart";

const colors = [
  "var(--chart-blue-1)",
  "var(--chart-blue-2)",
  "var(--chart-blue-3)",
  "var(--chart-blue-4)",
  "var(--chart-blue-5)",
];

const chartConfig = {} satisfies ChartConfig;

const LegendList = ({ chartData }) => {
  return (
    <div className="flex flex-col justify-center mb-4">
      <h3 className="font-bold mb-3">Nonconformity cases by types</h3>
      <ul className="space-y-2 text-sm">
        {chartData.map((item) => (
          <li key={item.type} className="flex items-center gap-2">
            <span
              className="inline-block size-3 rounded-full"
              style={{ backgroundColor: item.fill }}
            />
            <span>{item.type}</span>
            <span className="ml-auto">{item.ncs}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

interface NonconformityChartProps {
  data: WidgetData;
}

export const NonconformityChart = ({ data }: NonconformityChartProps) => {
  const chartData = data?.widgetDataComponent.map(
    (item: WidgetDataComponent, index: number) => ({
      type: item.name,
      ncs: item.value,
      fill: colors[index],
    }),
  );

  if (!chartData || chartData.length === 0) {
    return (
      <EmptyChart
        legendTitle="Nonconformity cases by types"
        legendText="No cases"
      />
    );
  }
  return (
    <Card className="flex flex-col border-none shadow-none">
      <CardContent className="flex p-0 justify-center">
        <div className="flex flex-col sm:flex-row">
          <Chart
            config={chartConfig}
            chartId="nonconformity-pie-chart"
            className="mx-auto aspect-square min-h-[200px]"
          >
            <PieChart>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel={true} />}
              />
              <Pie
                data={chartData}
                dataKey="ncs"
                nameKey="type"
                innerRadius={50}
                strokeWidth={5}
                isAnimationActive={false}
              />
            </PieChart>
          </Chart>

          <LegendList chartData={chartData} />
        </div>
      </CardContent>
    </Card>
  );
};
